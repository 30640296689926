//@import "../../.build/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import 'overwrite-variables';

// Base font size für em/rem
$bf:    15;


// xlarge screen
$screen-lg-max: 1659px;
$screen-xl-min: 1660px;

// Custom Variablen
//$brand-primary:                     #006699;
$brand-primary:                     #e3192d;
$brand-primary-dark:                #005580;
$brand-primary-darker:              #004466;
$brand-primary-light:               #3385ad;
$color-logo:                        #0080c8;

$color-black:				        #000;
$color-white:				        #fff;

$color-red:                         #c51b1b;

$color-gray-dark:                   #333333;
$color-gray-darker:                 #282828;
$color-gray-semidark:               #5d5d5d;
$color-gray-semilight:              #969696;
$color-gray-lighter:                #c9c9c9;
$color-gray-light:                  #e5e5e5;
$color-gray-superlight:             #f3f3f3;

$color-text-footer:                 #b9b9b9;

$btn-default-bg:                    transparent;
$btn-default-color:                 $brand-primary;
$btn-default-border:                $brand-primary;

$btn-secondary-bg:                  $color-black;
$btn-secondary-color:               $color-white;
$btn-secondary-border:              lighten($btn-secondary-bg, 10%);

// Schriftgrößen und Margins für die Überschriften
// Reihenfolge: xs sm md lg
$header-sizes-h1:               25rem/$bf 35rem/$bf 35rem/$bf 45rem/$bf;
$header-sizes-h1-subheader:     15rem/$bf 15rem/$bf 15rem/$bf 25rem/$bf;
$header-margins-h1:             30rem/$bf 30rem/$bf 30rem/$bf 80rem/$bf;

//$header-sizes-h2:               30rem/$bf 30rem/$bf 35rem/$bf 35rem/$bf;
$header-sizes-h2:               25rem/$bf 35rem/$bf 35rem/$bf 45rem/$bf;
$header-sizes-h2-subheader:     20rem/$bf 20rem/$bf 20rem/$bf 25rem/$bf;
$header-margins-h2:             20rem/$bf 20rem/$bf 25rem/$bf 25rem/$bf;

/*
$header-sizes-h3:               25rem/$bf 25rem/$bf 30rem/$bf 30rem/$bf;
$header-sizes-h3-subheader:     15rem/$bf 15rem/$bf 15rem/$bf 20rem/$bf;
$header-margins-h3:             15rem/$bf 15rem/$bf 20rem/$bf 20rem/$bf;

$header-sizes-h4:               20rem/$bf 20rem/$bf 25rem/$bf 25rem/$bf;
$header-sizes-h4-subheader:     15rem/$bf 15rem/$bf 15rem/$bf 15rem/$bf;
$header-margins-h4:             10rem/$bf 10rem/$bf 15rem/$bf 15rem/$bf;

$header-sizes-h5:               15rem/$bf 15rem/$bf 15rem/$bf 15rem/$bf;
$header-sizes-h5-subheader:     15rem/$bf 15rem/$bf 15rem/$bf 15rem/$bf;
$header-margins-h5:             10rem/$bf 10rem/$bf 15rem/$bf 15rem/$bf;
*/

$header-sizes-h3:               $header-sizes-h1;
$header-sizes-h3-subheader:     $header-sizes-h1-subheader;
$header-margins-h3:             $header-margins-h1;
$header-sizes-h4:               $header-sizes-h1;
$header-sizes-h4-subheader:     $header-sizes-h1-subheader;
$header-margins-h4:             $header-margins-h1;
$header-sizes-h5:               $header-sizes-h1;
$header-sizes-h5-subheader:     $header-sizes-h1-subheader;
$header-margins-h5:             $header-margins-h1;

h2 {
  font-weight: 900;
  text-transform: uppercase;
  // -15px because of the box-shadow which requires 15px margin-top for the slider elements
  margin-bottom: 10rem/$bf;
  @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    margin-bottom: 13rem/$bf;
  }
  @media screen and (min-width: $screen-lg-min)  {
    margin-bottom: 60rem/$bf;
  }
}
.h1--textlogo h1, .h1--textlogo h2, .h2--textlogo h1, .h2--textlogo h2,
h1.h1--textlogo, h1.h2--textlogo, h2.h1--textlogo, h2.h2--textlogo {
  b, strong {
    display: inline-block;
    text-align: left;
    text-indent: -9999rem;
    width: 190rem/$bf;
    height: 22rem/$bf;
    margin-right: 16rem/$bf;
    background-image: url('../images/svg/logo-font-only.svg');
    background-repeat: no-repeat;
    background-size: contain;
    //background-position: center -52rem/$bf;
    background-position: center bottom;
    @media screen and (max-width: $screen-xs-max) {
      display: block;
      margin: 0 auto;
    }

    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 256rem/$bf;
      height: 31rem/$bf;
      margin-right: 20rem/$bf;
    }
    @media screen and (min-width: $screen-lg-min)  {
      width: 342rem/$bf;
      height: 40rem/$bf;
      margin-right: 45rem/$bf;
    }
  }
}


// Loading Spinner
//$loading-spinner-color:         $color-alto;

// Bootstrap

// customize bootstrap
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

$grid-gutter-width:     30rem/$bf;

$border-radius-base:        0;
$padding-base-vertical:     10px;
$padding-base-horizontal:   30px;

$body-bg: $color-white;

$font-family-sans-serif:	"Lato", Arial, Helvetica, sans-serif;
$font-family-serif:         Georgia, "Times New Roman", Times, serif;
$font-family-base:          $font-family-sans-serif;
$font-size-base:			20px;
$text-color:				$color-black;

// Panels
//$panel-bg:                    $color-alabaster;
$panel-body-padding:          15px !default;
$panel-heading-padding:       10px 15px !default;
$panel-footer-padding:        $panel-heading-padding;
$panel-border-radius:         $border-radius-base;

//** Border color for elements within panels
//$panel-inner-border:          $color-alabaster !default;
//$panel-footer-bg:             $color-alabaster;

$panel-default-text:          $text-color;
//$panel-default-border:        $color-mercury;
//$panel-default-heading-bg:    $color-alabaster;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   15px;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-bg:                 none;
//$breadcrumb-color:              $color-dove-gray;
$breadcrumb-active-color:       $text-color;
$breadcrumb-separator:          "|";


//== Tables
//
$table-cell-padding:            15rem/$bf 25rem/$bf;
$table-condensed-cell-padding:  5px;

$table-bg:                      transparent;
$table-bg-accent:               transparent;
$table-bg-hover:                $color-gray-superlight;
$table-bg-active:               $table-bg-hover;
$table-border-color:            $color-gray-light;

//== Buttons

$btn-default-color:              $color-black;
$btn-default-bg:                 transparent;
$btn-default-border:             $color-black;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $brand-primary;

$btn-secondary-color:            #fff;
$btn-secondary-bg:               #333333;
$btn-secondary-border:           #333333;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $btn-success-bg;

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $btn-info-bg;

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $btn-warning-bg;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $btn-danger-bg;

$btn-border-radius-base:         0;
$btn-border-radius-large:        0;


//$btn-link-disabled-color:        $gray-light;

//== Forms
//
$input-bg:                       #fff;
$input-color:                    $color-black;
$input-border-radius:            $border-radius-base;
//$input-border:                   $color-alto;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$alert-border-radius:            0;
$alert-padding:                  22px 25px;

$alert-info-bg:                  #f0f1f1;
$alert-info-text:                #000;
$alert-info-border:              #000;
/*
$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;
*/

// tabs
$nav-tabs-border-color:						$color-gray-light;
$nav-tabs-link-hover-border-color:			$nav-tabs-border-color;
$nav-tabs-bg:                               $brand-primary;
$nav-tabs-active-link-hover-bg:             $brand-primary;
$nav-tabs-active-link-hover-color:          $color-white;
$nav-tabs-active-link-hover-border-color:	$nav-tabs-border-color;


//== Labels
//
//##

//** Default label background color
//$label-default-bg:            $color-mercury;
//** Primary label background color
$label-primary-bg:            $brand-primary;
//** Success label background color
$label-success-bg:            $brand-success;
//** Info label background color
$label-info-bg:               $brand-info;
//** Warning label background color
$label-warning-bg:            $brand-warning;
//** Danger label background color
$label-danger-bg:             $brand-danger;

//** Default label text color
$label-color:                 $text-color;
//** Default text color of a linked label
$label-link-hover-color:      #fff;


// Main Nav
$main-nav-padding-level-1:    20px;
//$main-nav-hover-intent-delay: 250ms;

// Subnav
$sub-nav-level-1-bg: $color-white;
$sub-nav-level-1-color: $color-white;
$sub-nav-level-1-bg-hover: darken($color-white, 5%);

$sub-nav-level-2-color: $color-black;
$sub-nav-level-2-bg-active: $color-black;

$sub-nav-level-3-color: $color-black;
$sub-nav-level-3-bg-active: lighten($color-black, 5%);


// Content Elemente
$content-element-header-special-border-color: $color-white;
$content-element-carousel-background: $color-white;

$content-element-accordion-heading-bg: $brand-primary;
$content-element-accordion-heading-color: $color-white;
$content-element-accordion-body-bg: transparent;
$content-element-accordion-body-border-color: $color-gray-light;