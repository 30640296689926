@import "../.build/node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "config/variables";

body {
    background: #fff;
    color: #000;
    font-family: Arial, Helvetica, sans-serf;
    font-size: 24px;
    font-weight: 400;
}

h1 {
    font-size: 48px;
    font-weight: bold;
    width: 278px;
    line-height: 43px;
    margin: 0 0 130px 295px;
}

p {
    line-height: 30px;

    &.fallback-message {
        text-align: center;
    }
}

.logo {
    display: block;
    width: 400px;
    position: absolute;
    top: 120px;
    left: 50%;
    margin-left: -200px;
    text-align: center;

    > img {
        display: inline-block;
        max-width: 100%;
    }
}

.neos-error-screen {
    width: 552px;
    min-height: 591px;
    padding: 300px 40px 0 40px;
    margin: 0 auto;
    background: url(../images/bg-error-page.png) no-repeat 0 55px transparent;
}

.meta-nav {
    text-align: center;
    margin-top: 30px;

    ul {
        margin: 0;
        padding: 0;
        overflow: hidden;

        li {
            display: block;
            float: left;

            a {
                display: block;
                background: $brand-primary;
                color: #fff;
                text-decoration: none;
                margin: 0 5px 5px 0;
                font-weight: 400;
                padding: 0 10px;
                height: 38px;
                line-height: 38px;
                transition: background-color 0.3s;

                &:hover {
                    background: $color-black;
                }
            }
        }
    }
}